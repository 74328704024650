<template>
    <v-simple-table height="450px" fixed-header>
        <template v-slot:default>
            <thead>
            <tr>
                <th class="text-left">
                
                </th>
                <th class="text-center">
                Mattina
                </th>
                <th class="text-center">
                Pomeriggio
                </th>
            </tr>
            </thead>
            <tbody>
            <tr
                v-for="(weekDay, index) in orderedWeekDayKeys"
                :key="index"
            >
                <td>
                <span>{{weekDay.text}}</span>
                </td>
                <td>
                <v-container>
                    <v-row justify="center" align="center">
                    <v-col
                        cols="12"
                        md="5"
                    >
                        <TimeInput
                        :valueTime.sync="inputWeekWorkTimes[weekDay.key].morningTimes.clockInTime"
                        :prependIcon="'mdi-import'"
                        :label="'Ingresso'"
                        :rules="[() => workScheduleTimeRules[weekDay.key].morning.inRule]"
                        :min="workScheduleTimeBoundaries[weekDay.key].morning.inBounds.min"
                        :max="workScheduleTimeBoundaries[weekDay.key].morning.inBounds.max"
                        />
                    </v-col>

                    <v-col
                        cols="12"
                        md="5"
                    >
                        <TimeInput
                        :valueTime.sync="inputWeekWorkTimes[weekDay.key].morningTimes.clockOutTime"
                        :prependIcon="'mdi-export'"
                        :label="'Uscita'"
                        :rules="[() => workScheduleTimeRules[weekDay.key].morning.outRule]"
                        :min="workScheduleTimeBoundaries[weekDay.key].morning.outBounds.min"
                        :max="workScheduleTimeBoundaries[weekDay.key].morning.outBounds.max"
                        :disabled="inputWeekWorkTimes[weekDay.key].morningTimes.clockInTime == null"
                        />
                    </v-col>

                    <v-col
                        cols="12"
                        md="2"
                    >
                        <v-icon
                        small
                        class="mr-2"
                        @click="
                            inputWeekWorkTimes[weekDay.key].morningTimes.clockInTime = null
                            inputWeekWorkTimes[weekDay.key].morningTimes.clockOutTime = null
                        "
                        >
                        mdi-close-circle
                        </v-icon>
                    </v-col>

                    </v-row>
                </v-container>
                </td>
                <td>
                <v-container>
                    <v-row  justify="center" align="center">
                    <v-col
                        cols="12"
                        md="5"
                    >
                        <TimeInput
                        :valueTime.sync="inputWeekWorkTimes[weekDay.key].afternoonTimes.clockInTime"
                        :maxWidth="'290px'"
                        :minWidth="'290px'"
                        :prependIcon="'mdi-import'"
                        :label="'Ingresso'"
                        :refId="'afternoonClockIn' + weekDay.key"
                        :rules="[() => workScheduleTimeRules[weekDay.key].afternoon.inRule]"
                        :min="workScheduleTimeBoundaries[weekDay.key].afternoon.inBounds.min"
                        :max="workScheduleTimeBoundaries[weekDay.key].afternoon.inBounds.max"
                        />
                    </v-col>

                    <v-col
                        cols="12"
                        md="5"
                    >
                        <TimeInput
                        :valueTime.sync="inputWeekWorkTimes[weekDay.key].afternoonTimes.clockOutTime"
                        :maxWidth="'290px'"
                        :minWidth="'290px'"
                        :prependIcon="'mdi-export'"
                        :label="'Uscita'"
                        :refId="'afternoonClockOut' + weekDay.key"
                        :rules="[() => workScheduleTimeRules[weekDay.key].afternoon.outRule]"
                        :min="workScheduleTimeBoundaries[weekDay.key].afternoon.outBounds.min"
                        :max="workScheduleTimeBoundaries[weekDay.key].afternoon.outBounds.max"
                        :disabled="inputWeekWorkTimes[weekDay.key].afternoonTimes.clockInTime == null"
                        />
                    </v-col>

                    <v-col
                        cols="12"
                        md="2"
                    >
                        <v-icon
                        small
                        class="mr-2"
                        @click="
                            inputWeekWorkTimes[weekDay.key].afternoonTimes.clockInTime = null
                            inputWeekWorkTimes[weekDay.key].afternoonTimes.clockOutTime = null
                        "
                        >
                        mdi-close-circle
                        </v-icon>
                    </v-col>
                    </v-row>
                </v-container>
                </td>
            </tr>
            </tbody>
        </template>
    </v-simple-table>
</template>
<script>
export const TimeInput = () => import('./TimeInput.vue');
import { DateTime } from 'luxon'

const baseDateTime = DateTime.local(1970, 1, 1, 0, 0, 0);
const startOfBaseDateTime = baseDateTime.startOf('day');
const endOfBaseDateTime = baseDateTime.endOf('day');

export default {
    data: function() {
        return {
            orderedWeekDayKeys: [
                {text: 'Lunedì', key: 'mondayWorkTimes', morning: {clockInTimeInputMenu: false, clockOutTimeInputmenu: false}, afternoon: {clockInTimeInputMenu: false, clockOutTimeInputmenu: false}},
                {text: 'Martedì', key: 'tuesdayWorkTimes', morning: {clockInTimeInputMenu: false, clockOutTimeInputmenu: false}, afternoon: {clockInTimeInputMenu: false, clockOutTimeInputmenu: false}},
                {text: 'Mercoledì', key: 'wednesdayWorkTimes', morning: {clockInTimeInputMenu: false, clockOutTimeInputmenu: false}, afternoon: {clockInTimeInputMenu: false, clockOutTimeInputmenu: false}},
                {text: 'Giovedì', key: 'thursdayWorkTimes', morning: {clockInTimeInputMenu: false, clockOutTimeInputmenu: false}, afternoon: {clockInTimeInputMenu: false, clockOutTimeInputmenu: false}},
                {text: 'Venerdì', key: 'fridayWorkTimes', morning: {clockInTimeInputMenu: false, clockOutTimeInputmenu: false}, afternoon: {clockInTimeInputMenu: false, clockOutTimeInputmenu: false}},
                {text: 'Sabato', key: 'saturdayWorkTimes', morning: {clockInTimeInputMenu: false, clockOutTimeInputmenu: false}, afternoon: {clockInTimeInputMenu: false, clockOutTimeInputmenu: false}},
                {text: 'Domenica', key: 'sundayWorkTimes', morning: {clockInTimeInputMenu: false, clockOutTimeInputmenu: false}, afternoon: {clockInTimeInputMenu: false, clockOutTimeInputmenu: false}},
            ],
        }
    },
    components: {
        TimeInput,
    },
    props: {
        value: {
            type: Object,
            default: () => {
                return {
                    mondayWorkTimes: {
                        morningTimes: { clockInTime: null, clockOutTime: null, },
                        afternoonTimes: { clockInTime: null, clockOutTime: null, },
                    },
                    tuesdayWorkTimes: {
                        morningTimes: { clockInTime: null, clockOutTime: null, },
                        afternoonTimes: { clockInTime: null, clockOutTime: null, },
                    },
                    wednesdayWorkTimes: {
                        morningTimes: { clockInTime: null, clockOutTime: null, },
                        afternoonTimes: { clockInTime: null, clockOutTime: null, },
                    },
                    thursdayWorkTimes: {
                        morningTimes: { clockInTime: null, clockOutTime: null, },
                        afternoonTimes: { clockInTime: null, clockOutTime: null, },
                    },
                    fridayWorkTimes: {
                        morningTimes: { clockInTime: null, clockOutTime: null, },
                        afternoonTimes: { clockInTime: null, clockOutTime: null, },
                    },
                    saturdayWorkTimes: {
                        morningTimes: { clockInTime: null, clockOutTime: null, },
                        afternoonTimes: { clockInTime: null, clockOutTime: null, },
                    },
                    sundayWorkTimes: {
                        morningTimes: { clockInTime: null, clockOutTime: null, },
                        afternoonTimes: { clockInTime: null, clockOutTime: null, },
                    }
                }
            }
        }
    },
    methods: {
        parseISODateTime (stringTime) {
        if (stringTime) {
            let parsedTime = DateTime.fromISO(stringTime);
            if(parsedTime.invalid == null) {
            return parsedTime
            } else {
            return null;
            }
        } else {
            return null;
        }
        }
    },
    computed: {
        inputWeekWorkTimes: {
            get: function() {
                return this.value;
            },
            set: function(val) {
                if(this.value != val) {
                    this.$emit('input', val);
                }
            }
        },
        workScheduleTimeRules () {
            let rules = {};
            let weekDayTimes = null;
            this.orderedWeekDayKeys.forEach(weekDay => {
                weekDayTimes = this.inputWeekWorkTimes[weekDay.key]
                rules[weekDay.key] = {morning: {}, afternoon: {}}
                rules[weekDay.key].morning.inRule = ((!weekDayTimes.morningTimes.clockOutTime || !!weekDayTimes.morningTimes.clockInTime ) || 'Incomplete time schedule')
                rules[weekDay.key].morning.outRule = ((!weekDayTimes.morningTimes.clockInTime || !!weekDayTimes.morningTimes.clockOutTime ) || 'Incomplete time schedule')
                rules[weekDay.key].afternoon.inRule = ((!weekDayTimes.afternoonTimes.clockOutTime || !!weekDayTimes.afternoonTimes.clockInTime ) || 'Incomplete time schedule')
                rules[weekDay.key].afternoon.outRule = ((!weekDayTimes.afternoonTimes.clockInTime || !!weekDayTimes.afternoonTimes.clockOutTime ) || 'Incomplete time schedule')
            })
            return rules;
        },
        workScheduleTimeBoundaries () {
            let boundaries = {};
            let weekDayTimes = null;
            this.orderedWeekDayKeys.forEach(weekDay => {
                weekDayTimes = this.inputWeekWorkTimes[weekDay.key]
                let parsedMorningIn = this.parseISODateTime(weekDayTimes.morningTimes.clockInTime);
                let parsedMorningOut = this.parseISODateTime(weekDayTimes.morningTimes.clockOutTime);
                let parsedAfternoonIn = this.parseISODateTime(weekDayTimes.afternoonTimes.clockInTime);
                let parsedAfternoonOut = this.parseISODateTime(weekDayTimes.afternoonTimes.clockOutTime);

                boundaries[weekDay.key] = {morning: {}, afternoon: {}}
                boundaries[weekDay.key].morning.inBounds = {
                min: startOfBaseDateTime.toISO(),
                max: DateTime.min((parsedMorningOut || endOfBaseDateTime), (parsedAfternoonIn || endOfBaseDateTime),  (parsedAfternoonOut || endOfBaseDateTime)).toISO()
                }
                boundaries[weekDay.key].morning.outBounds = {
                min: (parsedMorningIn || startOfBaseDateTime).toISO(),
                max: DateTime.min((parsedAfternoonIn || endOfBaseDateTime),  (parsedAfternoonOut || endOfBaseDateTime)).toISO()
                }
                boundaries[weekDay.key].afternoon.inBounds = {
                min: DateTime.max((parsedMorningIn || startOfBaseDateTime), (parsedMorningOut || startOfBaseDateTime)).toISO(),
                max: (parsedAfternoonOut || endOfBaseDateTime).toISO()
                }
                boundaries[weekDay.key].afternoon.outBounds = {
                min: DateTime.max((parsedMorningIn || startOfBaseDateTime), (parsedMorningOut || startOfBaseDateTime),  (parsedAfternoonIn || startOfBaseDateTime)).toISO(),
                max: endOfBaseDateTime.toISO()
                }
            })
            return boundaries;
        },
    }
}
</script>
